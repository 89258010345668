import { ReactElement, forwardRef, useCallback, ChangeEvent, FocusEvent } from "react";

import Grid from "@mui/material/Grid";

import { useCurrentInputVibe } from "../../../hooks/useCurrentInputVibe";
import { useCustomError } from "../../../hooks/useCustomError";
import { useDebounce } from "../../TextField/useDebounce";
import { BaseInput } from "../BaseInput/BaseInput";
import { InputLabel } from "../InputLabel/InputLabel";

import { BaseTextFieldProps, truncateTypes } from "./types";

export const BaseTextField = forwardRef<HTMLInputElement, BaseTextFieldProps>(
  (
    {
      classes,
      className,
      vibe,
      debounced,
      defaultValue,
      error,
      errorMessage,
      helperText,
      headerRight,
      InputProps,
      label,
      endElement,
      onBlur,
      onChange,
      onChangeNotDebounced,
      tooltipText,
      type,
      "data-test": dataTest,
      truncate = true,
      ...rest
    },
    ref
  ): ReactElement<BaseTextFieldProps> => {
    const currentInputVibe = useCurrentInputVibe(vibe);

    const currentError = useCustomError(error, errorMessage);

    const handleChange = useCallback(
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.target.type === "number") {
          const value = event.target.value.length > 0 ? parseFloat(event.target.value) : null;
          onChange?.({
            ...event,
            target: {
              ...event.target,
              value: value as never,
            },
          });
        } else {
          onChange?.(event);
        }
      },
      [onChange]
    );

    const { setDebouncedValue } = useDebounce({
      debounced,
      onChange,
    });

    const handleChangeDebounced = useCallback(
      (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        onChangeNotDebounced?.(event);
        setDebouncedValue(event);
      },
      [onChangeNotDebounced, setDebouncedValue]
    );

    const handleBlur = useCallback(
      (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (
          truncate &&
          truncateTypes.includes(event.target.type) &&
          event.target.value !== event.target.value?.trim()
        ) {
          const truncatedEvent = { ...event, target: { ...event.target, value: event.target.value?.trim() } };
          if (debounced) {
            handleChangeDebounced?.(truncatedEvent);
          } else {
            handleChange?.(truncatedEvent);
          }
          onBlur?.(truncatedEvent);
        } else {
          onBlur?.(event);
        }
      },
      [debounced, handleChange, handleChangeDebounced, onBlur, truncate]
    );

    const onWheel = useCallback(
      (event: React.WheelEvent<HTMLDivElement>) =>
        type === "number" ? event.currentTarget.querySelector("input")?.blur() : null,
      [type]
    );

    const { inputLabel: inputLabelClasses, ...classesRest } = classes || {};

    return (
      <InputLabel
        classes={inputLabelClasses}
        className={className}
        vibe={currentInputVibe}
        data-test={dataTest}
        errorMessage={errorMessage}
        helperText={helperText}
        headerRight={headerRight}
        label={label}
        tooltipText={tooltipText}
      >
        <Grid container spacing={1} alignItems="center">
          <Grid item xs>
            <BaseInput
              {...rest}
              ref={ref}
              classes={classesRest}
              className={InputProps?.className}
              vibe={currentInputVibe}
              error={currentError}
              defaultValue={defaultValue}
              onBlur={handleBlur}
              onChange={debounced ? handleChangeDebounced : handleChange}
              type={type}
              onWheel={onWheel}
            />
          </Grid>
          {endElement && <Grid item>{endElement}</Grid>}
        </Grid>
      </InputLabel>
    );
  }
);

BaseTextField.displayName = "BaseTextField";
