import { memo } from "react";

import MaterialPopover from "@mui/material/Popover";
import { Theme, styled } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles";

import { InternalClasses } from "../helpers";
import { Icon } from "../Icon";

import { InfoboxBaseClasses, InfoboxVibe, InfoboxPointerClasses, InfoboxPopoverBaseClasses } from "./types";

type InfoBoxVibeExcludingNeutral = Exclude<InfoboxVibe, "neutral">;
const getSurfaceVibe = (theme: Theme, vibeKey: InfoBoxVibeExcludingNeutral): string =>
  theme.palette.surface[vibeKey].quiet;

export const getVibeStyles = (theme: Theme, vibeKey: InfoBoxVibeExcludingNeutral): CSSProperties => ({
  background: getSurfaceVibe(theme, vibeKey),
});

const getArrowStylesVibes = (theme: Theme, vibeKey: InfoBoxVibeExcludingNeutral): CSSProperties => ({
  fill: getSurfaceVibe(theme, vibeKey),
});

export const pointerHeight = 12;
const pointerWidth = 18;

const POINTER_PREFIX = "InfoboxPointer";

export const internalPointerClasses: InternalClasses<typeof POINTER_PREFIX, InfoboxPointerClasses> = {
  root: `${POINTER_PREFIX}-root`,
  top: `${POINTER_PREFIX}-top`,
  bottom: `${POINTER_PREFIX}-bottom`,
  left: `${POINTER_PREFIX}-left`,
  right: `${POINTER_PREFIX}-right`,
  ["top-right"]: `${POINTER_PREFIX}-top-right`,
  ["top-left"]: `${POINTER_PREFIX}-top-left`,
  ["bottom-right"]: `${POINTER_PREFIX}-bottom-right`,
  ["bottom-left"]: `${POINTER_PREFIX}-bottom-left`,
  brand: `${POINTER_PREFIX}-brand`,
  danger: `${POINTER_PREFIX}-danger`,
  warning: `${POINTER_PREFIX}-warning`,
  positive: `${POINTER_PREFIX}-positive`,
  neutral: `${POINTER_PREFIX}-neutral`,
};

export const StyledIcon = memo(
  styled(Icon)(({ theme }: { theme: Theme }) => ({
    [`&.${internalPointerClasses.root}`]: {
      position: "absolute",
      width: 18,
      height: pointerHeight,
    },

    [`&.${internalPointerClasses.top}`]: {
      top: -pointerHeight + 1,
      left: `calc(50% - ${pointerWidth / 2}px)`,
    },

    [`&.${internalPointerClasses["top-right"]}`]: {
      left: "unset",
      right: theme.spacing(5),
    },

    [`&.${internalPointerClasses["top-left"]}`]: {
      left: theme.spacing(5),
      right: "unset",
    },

    [`&.${internalPointerClasses.bottom}`]: {
      rotate: "180deg",
      bottom: -pointerHeight + 1,
      left: `calc(50% - ${pointerWidth / 2}px)`,
    },

    [`&.${internalPointerClasses["bottom-right"]}`]: {
      left: "unset",
      right: theme.spacing(5),
    },

    [`&.${internalPointerClasses["bottom-left"]}`]: {
      left: theme.spacing(5),
      right: "unset",
    },

    [`&.${internalPointerClasses.left}`]: {
      rotate: "270deg",
      top: `calc(50% - ${pointerHeight / 2}px)`,
      left: -pointerHeight,
    },

    [`&.${internalPointerClasses.right}`]: {
      rotate: "90deg",
      top: `calc(50% - ${pointerHeight / 2}px)`,
      right: -pointerHeight,
    },

    [`&.${internalPointerClasses.brand}`]: getArrowStylesVibes(theme, "brand"),
    [`&.${internalPointerClasses.danger}`]: getArrowStylesVibes(theme, "danger"),
    [`&.${internalPointerClasses.warning}`]: getArrowStylesVibes(theme, "warning"),
    [`&.${internalPointerClasses.positive}`]: getArrowStylesVibes(theme, "positive"),
    [`&.${internalPointerClasses.neutral}`]: { fill: theme.palette.surface.neutral.supershy },
  }))
);

const INFOBOX_PREFIX = "ZeffyInfobox";

export const zeffyInfoboxClasses: InternalClasses<typeof INFOBOX_PREFIX, InfoboxBaseClasses> = {
  wrapper: `${INFOBOX_PREFIX}-wrapper`,
  body: `${INFOBOX_PREFIX}-body`,
  paper: `${INFOBOX_PREFIX}-paper`,
  iconAndContent: `${INFOBOX_PREFIX}-iconAndContent`,
  content: `${INFOBOX_PREFIX}-content`,
  contentPaddingTop: `${INFOBOX_PREFIX}-contentPaddingTop`,
  brand: `${INFOBOX_PREFIX}-brand`,
  danger: `${INFOBOX_PREFIX}-danger`,
  warning: `${INFOBOX_PREFIX}-warning`,
  positive: `${INFOBOX_PREFIX}-positive`,
  neutral: `${INFOBOX_PREFIX}-neutral`,
  icon: `${INFOBOX_PREFIX}-icon`,
  buttons: `${INFOBOX_PREFIX}-buttons`,
  buttonsMedium: `${INFOBOX_PREFIX}-buttonsMedium`,
  button: `${INFOBOX_PREFIX}-button`,
  title: `${INFOBOX_PREFIX}-title`,
  container: `${INFOBOX_PREFIX}-container`,
  close: `${INFOBOX_PREFIX}-close`,
  closeMedium: `${INFOBOX_PREFIX}-closeMedium`,
  actions: `${INFOBOX_PREFIX}-actions`,
  actionsSmall: `${INFOBOX_PREFIX}-actionsSmall`,
  link: `${INFOBOX_PREFIX}-link`,
  linkContainer: `${INFOBOX_PREFIX}-linkContainer`,
  centerIcon: `${INFOBOX_PREFIX}-centerIcon`,
};

export const StyledInfobox = memo(
  styled("div")(({ theme }: { theme: Theme }) => ({
    [`&.${zeffyInfoboxClasses.wrapper}`]: {
      borderRadius: theme.spacing(1),
      position: "relative",
    },

    [`& .${zeffyInfoboxClasses.body}`]: {
      overflow: "hidden",
      display: "flex",
      borderRadius: theme.spacing(1),
    },

    [`& .${zeffyInfoboxClasses.paper}`]: {
      padding: theme.spacing(1.5, 2),
      width: "100%",
      overflow: "hidden",
    },

    [`& .${zeffyInfoboxClasses.iconAndContent}`]: {
      display: "flex",
      flex: 1,
    },

    [`& .${zeffyInfoboxClasses.centerIcon}`]: {
      alignItems: "center",
    },

    [`& .${zeffyInfoboxClasses.content}`]: {
      display: "flex",
      flex: 1,
      flexDirection: "column",
    },

    [`& .${zeffyInfoboxClasses.contentPaddingTop}`]: {
      paddingTop: theme.spacing(0.25),
    },

    [`&.${zeffyInfoboxClasses.brand}`]: getVibeStyles(theme, "brand"),
    [`&.${zeffyInfoboxClasses.danger}`]: getVibeStyles(theme, "danger"),
    [`&.${zeffyInfoboxClasses.warning}`]: getVibeStyles(theme, "warning"),
    [`&.${zeffyInfoboxClasses.positive}`]: getVibeStyles(theme, "positive"),
    [`&.${zeffyInfoboxClasses.neutral}`]: { background: theme.palette.surface.neutral.supershy },

    [`& .${zeffyInfoboxClasses.icon}`]: {
      marginRight: theme.spacing(1.5),
    },

    [`& .${zeffyInfoboxClasses.buttons}`]: {
      display: "flex",
      alignItems: "center",
      justifyContent: "flex-end",
      marginLeft: theme.spacing(1.5),
    },

    [`& .${zeffyInfoboxClasses.buttonsMedium}`]: {
      width: "100%",
      marginTop: theme.spacing(1.5),
      marginLeft: 0,
    },

    [`& .${zeffyInfoboxClasses.button}`]: {
      marginRight: theme.spacing(1),
      "&:last-child": {
        marginRight: 0,
      },
    },

    [`& .${zeffyInfoboxClasses.title}`]: {
      marginBottom: theme.spacing(0.5),
    },

    [`& .${zeffyInfoboxClasses.container}`]: {
      display: "flex",
      width: "100%",
      alignItems: "center",
    },

    [`& .${zeffyInfoboxClasses.close}`]: {
      marginLeft: theme.spacing(1),
      display: "flex",
    },

    [`& .${zeffyInfoboxClasses.closeMedium}`]: {
      alignSelf: "flex-start",
      marginLeft: theme.spacing(1.5),
    },

    [`& .${zeffyInfoboxClasses.actions}`]: {
      alignSelf: "flex-start",
    },

    [`& .${zeffyInfoboxClasses.actionsSmall}`]: {
      display: "flex",
      alignSelf: "center",
      alignItems: "center",
      justifyContent: "center",
    },

    [`& .${zeffyInfoboxClasses.link}`]: {
      display: "inline-block",
      marginRight: theme.spacing(1),
    },

    [`& .${zeffyInfoboxClasses.linkContainer}`]: {
      [`& ${zeffyInfoboxClasses.link}:last-child`]: {
        marginRight: 0,
      },
    },
  }))
);

export const internalPopoverClasses: InternalClasses<typeof INFOBOX_PREFIX, InfoboxPopoverBaseClasses> = {
  popover: `${INFOBOX_PREFIX}-popover`,
};

export const StyledPopover = memo(
  styled(MaterialPopover)(({ theme }: { theme: Theme }) => ({
    [`& .${internalPopoverClasses.popover}`]: {
      overflow: "unset",
      boxShadow: theme.boxShadow.base.strong,
    },
  }))
);
