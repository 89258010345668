import { forwardRef } from "react";

import classnames from "classnames";

import { Icon } from "../Icon";
import { useMergeClasses } from "../useMergeClasses";

import { zeffyChipClasses, StyledChip } from "./styles";
import { ChipProps } from "./types";

import { Cross as Close } from "@/icons/outlined";

export const chipDeleteButtonDataTest = "chip-close-button";

export const Chip = forwardRef<HTMLDivElement, ChipProps>(
  (
    {
      classes: externalClasses,
      vibe = "neutral",
      disabled = false,
      icon,
      label,
      onClick,
      onDelete,
      variant = "filled",
      tabIndex: inputTabIndex,
      disableOnClickHover = false,
      ...rest
    },
    ref
  ) => {
    const classes = useMergeClasses(zeffyChipClasses, externalClasses);

    const isInteractive = Boolean(onClick) && !disabled && !disableOnClickHover;

    // Saving this logic for when we need to improve the accessibility of the component
    // const tabIndex = inputTabIndex ?? isInteractive ? 0 : undefined;
    return (
      <StyledChip
        {...rest}
        classes={{
          disabled: classes.disabled,
          label: classes.label,
          root: classnames(classes.root, classes[`${vibe}-${variant}`], {
            [classes.interactive]: isInteractive,
            [classes[`${vibe}-${variant}-interactive`]]: isInteractive,
          }),
          deletable: classes.deletable,
        }}
        icon={
          icon && (
            <>
              <Icon className={classes.icon} vibe="inherit" size="extraSmall">
                {icon}
              </Icon>
            </>
          )
        }
        deleteIcon={
          // Fragment is needed to stop MUI from overriding the styles
          <>
            <Icon
              className={classes.deleteIcon}
              onClick={onDelete}
              size="extraSmall"
              vibe="inherit"
              data-test={chipDeleteButtonDataTest}
            >
              <Close />
            </Icon>
          </>
        }
        label={
          // Fragment is needed to stop MUI from overriding the styles
          <>
            <span className={classes.labelContent}>{label}</span>
          </>
        }
        clickable={false}
        disabled={disabled}
        onClick={onClick}
        onDelete={onDelete}
        ref={ref}
        tabIndex={inputTabIndex}
      />
    );
  }
);

Chip.displayName = "Chip";
