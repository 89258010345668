import { memo } from "react";

import MaterialDivider from "@mui/material/Divider";
import { styled, Theme } from "@mui/material/styles";

export const StyledDivider = memo(
  styled(MaterialDivider)(({ theme }: { theme: Theme }) => ({
    borderColor: theme.palette.border.neutral.quiet,
  }))
);
