import { useState, useEffect } from "react";

import { AmplitudeVariantValue } from "@simplyk/common";

import { GetExperimentValueProps, useAmplitude } from "./useAmplitude";

// Retrieve a feature flag from Amplitude that resolves "b" value to true,
// others to false.
export const useAmplitudeFeatureFlag = ({
  skip,
  ...rest
}: GetExperimentValueProps & {
  skip?: boolean;
}) => {
  const { getExperimentValue, isAmplitudeLoading } = useAmplitude();

  const [isFlagValueActive, setIsFlagValueActive] = useState<boolean | null>(null);
  const [flagValue, setFlagValue] = useState<AmplitudeVariantValue | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (isAmplitudeLoading) {
      return;
    }

    const fetchFlag = async () => {
      setLoading(true);

      const flag = await getExperimentValue(rest);

      if (!flag) {
        setLoading(false);
        return;
      }

      // eslint-disable-next-line no-console
      console.log(`🅰️ flag exposure: ${rest?.flagKey}=${flag?.value}`);
      setIsFlagValueActive(flag?.value === "b");
      setFlagValue((flag?.value as AmplitudeVariantValue) || null);

      setLoading(false);
    };
    if (!skip) {
      fetchFlag();
    } else {
      setLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [getExperimentValue, JSON.stringify(rest), skip, isAmplitudeLoading]);

  return { isActive: Boolean(isFlagValueActive), loading: loading || isAmplitudeLoading, value: flagValue };
};
