import { Day, PaymentMethod, PayoutInterval } from "../enums";
export const StripeMonthlyAnchor = 3;
export const StripeWeeklyAnchor = Day.Monday;
export const CharityMCC = 8398;
const day = {
    [Day.Monday]: 1,
    [Day.Tuesday]: 2,
    [Day.Wednesday]: 3,
    [Day.Thursday]: 4,
    [Day.Friday]: 5,
    [Day.Saturday]: 6,
    [Day.Sunday]: 7,
};
const payoutWeekklyAnchorNumber = day[StripeWeeklyAnchor];
export const getNextPayoutDate = (date, interval) => {
    if (interval === PayoutInterval.Monthly) {
        if (date.getDate() <= StripeMonthlyAnchor) {
            return new Date(date.getFullYear(), date.getMonth(), StripeMonthlyAnchor, 12);
        }
        else {
            return new Date(date.getFullYear(), date.getMonth() + 1, StripeMonthlyAnchor, 12);
        }
    }
    if (interval === PayoutInterval.Weekly) {
        return new Date(date.setDate(date.getDate() + ((payoutWeekklyAnchorNumber + 7 - date.getDay()) % 7 ?? 7)));
    }
    if (interval === PayoutInterval.Daily) {
        return new Date(date.setDate(date.getDate() + 1));
    }
};
const STRIPE_PAYMENT_METHOD = ["card", "acss_debit", "us_bank_account", "apple_pay", "google_pay", "cashapp"];
export const PAYMENT_METHOD_FROM_STRIPE = {
    card: PaymentMethod.Card,
    acss_debit: PaymentMethod.Pad,
    us_bank_account: PaymentMethod.Ach,
    apple_pay: PaymentMethod.ApplePayOrGooglePay,
    google_pay: PaymentMethod.ApplePayOrGooglePay,
    cashapp: PaymentMethod.CashApp,
};
export const isStripePaymentMethod = (pm) => {
    return STRIPE_PAYMENT_METHOD.includes(pm);
};
