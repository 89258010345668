import { memo } from "react";

import MaterialChip from "@mui/material/Chip";
import { darken, Theme, styled } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles/withStyles";

import { zeffyTheme } from "../../../theme/theme";
import { INTERACTIVE_PSUEDO_CLASSES } from "../css";
import { InternalClasses, getClassesKeyMap, PrefixedKey, getRootStyleClassMap } from "../helpers";

import {
  ChipBaseClasses,
  ChipVibe,
  ChipVibeFilledClasses,
  ChipVibeFilledInteractiveClasses,
  ChipVibeOutlinedClasses,
  ChipVibeOutlinedInteractiveClasses,
} from "./types";

const PREFIX = "ZeffyChip";

const deleteIconClassMap: { deleteIcon: PrefixedKey<typeof PREFIX, "deleteIcon"> } = {
  deleteIcon: `${PREFIX}-deleteIcon`,
};

const getFilledBackground = (theme: Theme, vibeKey: ChipVibe): NonNullable<CSSProperties["color"]> =>
  vibeKey === "form" ? theme.palette.surface.form.moderate : theme.palette.surface[vibeKey].intense;

const getFilledStyles = (theme: Theme, vibeKey: ChipVibe): CSSProperties => {
  const textVibe = theme.palette.text.reverse.intense;
  const baseStyles = {
    background: getFilledBackground(theme, vibeKey),
    color: textVibe,
    fill: textVibe,
  };
  return {
    ...baseStyles,
    [INTERACTIVE_PSUEDO_CLASSES]: baseStyles,
  };
};

const getInteractiveFilledStyles = (theme: Theme, vibeKey: ChipVibe): CSSProperties => ({
  [INTERACTIVE_PSUEDO_CLASSES]: {
    background: darken(getFilledBackground(theme, vibeKey), 0.3),
  },
});

const getOutlinedBackground = (theme: Theme, vibeKey: ChipVibe): NonNullable<CSSProperties["color"]> =>
  theme.palette.surface[vibeKey].quiet;

const getOutlinedStyles = (theme: Theme, vibeKey: ChipVibe): CSSProperties => {
  const textVibe = theme.palette.text[vibeKey].intense;
  const deleteIconVibe = theme.palette.text[vibeKey].moderate;
  const baseStyles = {
    background: getOutlinedBackground(theme, vibeKey),
    border: `1px solid ${vibeKey === "form" ? theme.palette.border.form.quiet : theme.palette.border[vibeKey].moderate}`,
    color: textVibe,
    fill: textVibe,
    [`& .${deleteIconClassMap.deleteIcon}`]: {
      color: deleteIconVibe,
      fill: deleteIconVibe,
    },
  };
  return {
    ...baseStyles,
    [INTERACTIVE_PSUEDO_CLASSES]: baseStyles,
  };
};

const getInteractiveOutlinedStyles = (theme: Theme, vibeKey: ChipVibe): CSSProperties => ({
  [INTERACTIVE_PSUEDO_CLASSES]: {
    background: darken(getOutlinedBackground(theme, vibeKey), 0.1),
  },
});

const getAllVariantStyles = (
  theme: Theme
): Record<
  | ChipVibeFilledClasses
  | ChipVibeFilledInteractiveClasses
  | ChipVibeOutlinedClasses
  | ChipVibeOutlinedInteractiveClasses,
  CSSProperties
> => {
  const chipVibes: ChipVibe[] = ["brand", "neutral", "danger", "warning", "positive", "form"];

  const filledStyles = chipVibes.reduce(
    (styles, vibe) => {
      styles[`${vibe}-filled`] = getFilledStyles(theme, vibe);
      return styles;
    },
    {} as Record<ChipVibeFilledClasses, CSSProperties>
  );

  const interactiveFilledStyles = chipVibes.reduce(
    (styles, vibe) => {
      styles[`${vibe}-filled-interactive`] = getInteractiveFilledStyles(theme, vibe);
      return styles;
    },
    {} as Record<ChipVibeFilledInteractiveClasses, CSSProperties>
  );

  const outlinedStyles = chipVibes.reduce(
    (styles, vibe) => {
      styles[`${vibe}-outlined`] = getOutlinedStyles(theme, vibe);
      return styles;
    },
    {} as Record<ChipVibeOutlinedClasses, CSSProperties>
  );

  const interactiveOutlinedStyles = chipVibes.reduce(
    (styles, vibe) => {
      styles[`${vibe}-outlined-interactive`] = getInteractiveOutlinedStyles(theme, vibe);
      return styles;
    },
    {} as Record<ChipVibeOutlinedInteractiveClasses, CSSProperties>
  );

  return { ...filledStyles, ...interactiveFilledStyles, ...outlinedStyles, ...interactiveOutlinedStyles };
};

export const zeffyChipClasses: InternalClasses<typeof PREFIX, ChipBaseClasses> = {
  deletable: `${PREFIX}-deletable`,
  label: `${PREFIX}-label`,
  labelContent: `${PREFIX}-labelContent`,
  icon: `${PREFIX}-icon`,
  disabled: `${PREFIX}-disabled`,
  root: `${PREFIX}-root`,
  interactive: `${PREFIX}-interactive`,
  ...deleteIconClassMap,
  ...getClassesKeyMap(PREFIX, getAllVariantStyles(zeffyTheme)),
};

export const StyledChip = memo(
  styled(MaterialChip)(({ theme }: { theme: Theme }) => ({
    [`&.${zeffyChipClasses.deletable}`]: {},

    [`& .${zeffyChipClasses.label}`]: {
      ...theme.typography.caption,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      "&:hover, &:focus, &:focus-visible, &:focus-within": {
        outline: "none",
      },
    },

    [`& .${zeffyChipClasses.labelContent}`]: theme.mixins.truncateOverflow,

    [`& .${zeffyChipClasses.deleteIcon}`]: {
      marginLeft: theme.spacing(0.5),
      cursor: "pointer",
      "&:hover, &:focus, &:focus-visible, &:focus-within": {
        outline: "none",
      },
    },

    [`& .${zeffyChipClasses.icon}`]: {
      margin: theme.spacing(0, 0.5, 0, 0),
      "&:hover, &:focus, &:focus-visible, &:focus-within": {
        outline: "none",
      },
    },

    [`&.${zeffyChipClasses.disabled}`]: {},

    [`&.${zeffyChipClasses.root}`]: {
      height: 20,
      borderRadius: theme.radius(2),
      padding: theme.spacing(0.25, 1),
      maxWidth: "100%",
      "&:hover, &:focus, &:focus-visible, &:focus-within": {
        background: theme.palette.neutralVariant[95],
      },
      [`& .${zeffyChipClasses.label}`]: {
        padding: theme.spacing(0.25, 0),
      },
      [`& .${zeffyChipClasses.deleteIcon}`]: {
        padding: 0,
      },
    },

    ...getRootStyleClassMap(PREFIX, getAllVariantStyles(theme)),
    [`& .${zeffyChipClasses.interactive}`]: {
      cursor: "pointer",
      [INTERACTIVE_PSUEDO_CLASSES]: {
        outline: "none",
      },
    },
  }))
);
