import { memo } from "react";

import MaterialInputBase from "@mui/material/InputBase";
import { styled, Theme } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles/withStyles";

import { zeffyTheme } from "../../../theme/theme";
import { getTransitions } from "../css";
import { InternalClasses } from "../helpers";

import { BaseInputBaseClasses } from "./types";

import { InputVibe } from "@/types/mui";

const PREFIX = "ZeffyInput";

export const zeffyInputClasses: InternalClasses<typeof PREFIX, BaseInputBaseClasses> = {
  root: `${PREFIX}-root`,
  neutral: `${PREFIX}-neutral`,
  form: `${PREFIX}-form`,
  input: `${PREFIX}-input`,
  multiline: `${PREFIX}-multiline`,
  error: `${PREFIX}-error`,
  focused: `${PREFIX}-focused`,
  medium: `${PREFIX}-medium`,
  small: `${PREFIX}-small`,
  disabled: `${PREFIX}-disabled`,
  adornedStart: `${PREFIX}-adornedStart`,
  adornedEnd: `${PREFIX}-adornedEnd`,
  inputAdornedStart: `${PREFIX}-inputAdornedStart`,
  inputAdornedEnd: `${PREFIX}-inputAdornedEnd`,
  noPadding: `${PREFIX}-noPadding`,
};

export const getTopDownPadding = (size: "small" | "medium", theme: Theme): number =>
  size === "small" ? theme.legacySpacing(0.5) : theme.legacySpacing(1);

export const getRightLeftPadding = (theme: Theme): number => theme.legacySpacing(1);

export const getSizeStyles = (size: "small" | "medium", theme: Theme): CSSProperties => ({
  minHeight: 20,
  padding: `${getTopDownPadding(size, theme)}px  ${getRightLeftPadding(theme)}px`,
});

export const getBaseInputInputStyles = (theme: Theme): CSSProperties => ({
  ...theme.typography.body2,
  border: "none",
  borderRadius: theme.radius(1),
  height: "unset",
  margin: 0,
  outline: "none",
  padding: 0,
  boxSizing: "border-box",
  "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
    "-webkit-appearance": "none",
    margin: 0,
  },
  "&[type=number]": {
    "-moz-appearance": "textfield",
  },
  "&::placeholder": {
    opacity: 1,
  },
});

export const getBaseInputContainerStyles = (labelVibeKey: InputVibe, theme: Theme): CSSProperties => {
  const textVibe = theme.palette.text[labelVibeKey];
  const borderVibe = theme.palette.border[labelVibeKey];
  const surfaceVibe = theme.palette.surface[labelVibeKey];

  return {
    ...theme.typography.body2,
    ...getTransitions("all 50ms ease-out"),
    border: `1px solid ${borderVibe.quiet}`,
    background: surfaceVibe.white,
    borderRadius: theme.radius(1),
    color: textVibe.moderate,
    fill: textVibe.moderate,
    "& input::placeholder, & textarea::placeholder": {
      color: theme.constants.isFormV2 ? zeffyTheme.palette.text.form.supershy : theme.palette.text.form.supershy,
    },
    "&:hover": {
      borderColor: theme.palette.border.brand.intense,
    },
    [`&.${zeffyInputClasses.disabled}`]: {
      background: surfaceVibe.moderate,
      borderColor: borderVibe.moderate,
      color: textVibe.moderate,
      fill: textVibe.moderate,
      opacity: 0.4,
      "&:hover": {
        borderColor: borderVibe.moderate,
      },
    },
  };
};

export const getBaseInputFocusedStyles = (theme: Theme): CSSProperties => ({
  borderColor: theme.palette.border.brand.intense,
  boxShadow: `0 0 0 3px ${theme.palette.border.brand.moderate}`,
  outline: "none",
  width: "calc(100% - 6px)",
  marginRight: "3px",
  marginLeft: "3px",
});

export const getBaseInputErrorStyles = (theme: Theme): CSSProperties => ({
  borderColor: theme.palette.border.danger.intense,
});

export const StyledInputBase = memo(
  styled(MaterialInputBase)(({ theme }: { theme: Theme }) => ({
    [`&.${zeffyInputClasses.root}`]: {},
    [`&.${zeffyInputClasses.neutral}`]: getBaseInputContainerStyles("neutral", theme),
    [`&.${zeffyInputClasses.form}`]: getBaseInputContainerStyles("form", theme),
    [`& .${zeffyInputClasses.input}`]: getBaseInputInputStyles(theme),

    [`&.${zeffyInputClasses.multiline}`]: {
      [`& .${zeffyInputClasses.input}`]: {
        height: "100%",
      },
    },

    [`&.${zeffyInputClasses.error}`]: getBaseInputErrorStyles(theme),
    [`&.${zeffyInputClasses.focused}`]: getBaseInputFocusedStyles(theme),
    [`& .${zeffyInputClasses.medium}`]: getSizeStyles("medium", theme),
    [`& .${zeffyInputClasses.small}`]: getSizeStyles("small", theme),
    [`&.${zeffyInputClasses.disabled}`]: {},

    [`&.${zeffyInputClasses.adornedStart}`]: {
      paddingLeft: getRightLeftPadding(theme),
      backgroundColor: theme.palette.surface.neutral.white,
    },

    [`&.${zeffyInputClasses.adornedEnd}`]: {
      paddingRight: getRightLeftPadding(theme),
      backgroundColor: theme.palette.surface.neutral.white,
      [`&.${zeffyInputClasses.disabled}`]: {
        backgroundColor: theme.palette.surface.neutral.moderate,
      },
    },

    [`& .${zeffyInputClasses.inputAdornedStart}`]: {
      borderBottomLeftRadius: 0,
      borderTopLeftRadius: 0,
      marginLeft: theme.spacing(0.5),
      paddingLeft: theme.spacing(0.5),
      backgroundColor: theme.palette.surface.neutral.white,
    },

    [`& .${zeffyInputClasses.inputAdornedEnd}`]: {
      borderBottomRightRadius: 0,
      borderTopRightRadius: 0,
      marginRight: theme.spacing(0.5),
      paddingRight: theme.spacing(0.5),
      backgroundColor: theme.palette.surface.neutral.white,
      "&:disabled": {
        backgroundColor: theme.palette.surface.neutral.moderate,
      },
    },

    [`&.${zeffyInputClasses.noPadding}, & .${zeffyInputClasses.noPadding}`]: {
      padding: 0,
    },
  }))
);
