import { ChangeEvent, FocusEvent, Ref } from "react";

import { ClassNameMap } from "@mui/styles";
import { FieldValues } from "react-hook-form";

import { BaseInputBaseClasses, BaseInputProps } from "../BaseInput/types";
import { InputLabelProps } from "../InputLabel/types";

import { BaseFormFieldProps } from "@/types/baseFormField";

export const truncateTypes = ["email", "search", "text", "url"];

export interface BaseTextFieldProps
  extends Omit<BaseInputProps, "classes" | "onChange" | "onBlur">,
    Omit<InputLabelProps, "classes"> {
  classes?: Partial<ClassNameMap<BaseInputBaseClasses>> & {
    inputLabel?: Partial<InputLabelProps["classes"]>;
  };
  debounced?: number;
  InputProps?: { ref?: Ref<HTMLInputElement>; className?: string };
  onBlur?: (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChange?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  onChangeNotDebounced?: (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void;
  truncate?: boolean;
  noAsterisk?: boolean;
  name?: string;
  endElement?: React.ReactNode;
}

export interface FormTextFieldProps<T extends FieldValues>
  extends Omit<BaseTextFieldProps, "name">,
    Omit<BaseFormFieldProps<T>, "data-test"> {
  shouldUnregister?: boolean;
}

export interface TextFieldProps<T extends FieldValues> extends BaseTextFieldProps {
  control?: BaseFormFieldProps<T>["control"];
  rules?: BaseFormFieldProps<T>["rules"];
  name?: BaseFormFieldProps<T>["name"];
  shouldUnregister?: boolean;
}
