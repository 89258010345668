import { IsOnboardingParams } from "@simplyk/common";
import { useRouter } from "next/router";

export const useIsOnboarding = () => {
  const router = useRouter();
  const params: IsOnboardingParams = router.query;
  const isOnboarding = params.isOnboarding === "true";

  return { isOnboarding };
};
