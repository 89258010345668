import { Ref, RefAttributes, forwardRef, useCallback, ChangeEvent, FocusEvent, type JSX } from "react";

import { FieldValues, useController, useWatch } from "react-hook-form";

import { getLabel, isInputRequired } from "../../../helpers/validators";

import { BaseTextField } from "./BaseTextField";
import { FormTextFieldProps } from "./types";

import { useFormInputRefs } from "@/hooks/useFormInputRefs";
import { useValidators } from "@/hooks/useValidators";

function FormTextFieldInner<T extends FieldValues>(
  {
    control,
    debounced,
    defaultValue,
    name,
    onBlur,
    onChange,
    rules,
    shouldUnregister,
    inputRef,
    noAsterisk,
    ...rest
  }: FormTextFieldProps<T>,
  ref: Ref<HTMLInputElement>
) {
  const validators = useValidators(rules);
  const isRequired = isInputRequired(validators);

  const watchedValue = useWatch({ control, name });
  const internalDefaultValue = defaultValue || watchedValue;

  const { field } = useController<T>({
    name,
    control,
    defaultValue: internalDefaultValue as never,
    rules: validators,
    shouldUnregister,
  });

  const refCallBack = useFormInputRefs({ inputRefCallBack: field.ref, inputRef });

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (event) {
        onChange?.(event);
        field.onChange(event.target.value);
      }
    },
    [field, onChange]
  );

  const handleBlur = useCallback(
    (event: FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      onBlur?.(event);
      field.onBlur();
    },
    [field, onBlur]
  );

  return (
    <BaseTextField
      {...rest}
      // Cannot have a controlled debounced TextField for now
      debounced={debounced}
      // Unfortunately, useController does not allow us to defined the type of field.value
      // type-coverage:ignore-next-line
      value={debounced ? undefined : field.value}
      inputRef={refCallBack}
      ref={ref}
      defaultValue={debounced ? internalDefaultValue : undefined}
      onBlur={handleBlur}
      onChange={handleChange}
      data-test={rest?.["data-test"] || "text-field"}
      label={getLabel(isRequired, rest.label, noAsterisk)}
      name={name}
    />
  );
}

FormTextFieldInner.displayName = "FormTextField";

export const FormTextField = forwardRef(FormTextFieldInner) as unknown as <T extends FieldValues>(
  props: FormTextFieldProps<T> & RefAttributes<HTMLInputElement>
) => JSX.Element;
