import { memo } from "react";

import MaterialLink from "@mui/material/Link";
import { Theme, styled } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles/withStyles";

import { InternalClasses } from "../helpers";

import { LinkBaseClasses } from "./types";

import { ZeffyPaletteVibe } from "@/types/mui";

const getVibeStyles = (theme: Theme, vibe: ZeffyPaletteVibe): CSSProperties => ({
  textDecorationColor: theme.palette.text[vibe].moderate,
  color: theme.palette.text[vibe].moderate,
});

const PREFIX = "ZeffyLink";

export const zeffyLinkClasses: InternalClasses<typeof PREFIX, LinkBaseClasses> = {
  brand: `${PREFIX}-brand`,
  neutral: `${PREFIX}-neutral`,
  danger: `${PREFIX}-danger`,
  warning: `${PREFIX}-warning`,
  positive: `${PREFIX}-positive`,
  form: `${PREFIX}-form`,
  reverse: `${PREFIX}-reverse`,
  link: `${PREFIX}-link`,
  pointer: `${PREFIX}-pointer`,
  fullWidth: `${PREFIX}-fullWidth`,
};

export const StyledLink = memo(
  styled(MaterialLink)(({ theme }: { theme: Theme }) => ({
    [`&.${zeffyLinkClasses.brand}`]: getVibeStyles(theme, "brand"),
    [`&.${zeffyLinkClasses.neutral}`]: getVibeStyles(theme, "neutral"),
    [`&.${zeffyLinkClasses.danger}`]: getVibeStyles(theme, "danger"),
    [`&.${zeffyLinkClasses.warning}`]: getVibeStyles(theme, "warning"),
    [`&.${zeffyLinkClasses.positive}`]: getVibeStyles(theme, "positive"),
    [`&.${zeffyLinkClasses.form}`]: getVibeStyles(theme, "form"),
    [`&.${zeffyLinkClasses.reverse}`]: getVibeStyles(theme, "reverse"),

    [`&.${zeffyLinkClasses.link}`]: {
      textUnderlineOffset: "1px",
    },

    [`&.${zeffyLinkClasses.pointer}`]: {
      cursor: "pointer",
    },

    [`&.${zeffyLinkClasses.fullWidth}`]: {
      width: "100%",
    },
  }))
);
