import { memo } from "react";

import Box from "@mui/material/Box";
import { Theme, styled } from "@mui/material/styles";
import { CSSProperties } from "@mui/styles/withStyles";

import { InternalClasses } from "../helpers";

export type InputLabelBaseClasses =
  | "container"
  | "footerText"
  | "errorText"
  | "helperText"
  | "emptyFooter"
  | "header"
  | "headerRight"
  | "labelContainer"
  | "labelNeutral"
  | "labelForm";

const getLabelStyles = (vibeKey: "neutral" | "form", theme: Theme): CSSProperties => {
  const vibe = theme.palette.text[vibeKey];
  return {
    color: vibe.moderate,
    marginRight: theme.spacing(0.5),
  };
};
const PREFIX = "ZeffyInputLabel";

export const zeffyInputLabelClasses: InternalClasses<typeof PREFIX, InputLabelBaseClasses> = {
  container: `${PREFIX}-container`,
  header: `${PREFIX}-header`,
  labelContainer: `${PREFIX}-labelContainer`,
  labelNeutral: `${PREFIX}-labelNeutral`,
  labelForm: `${PREFIX}-labelForm`,
  headerRight: `${PREFIX}-headerRight`,
  footerText: `${PREFIX}-footerText`,
  helperText: `${PREFIX}-helperText`,
  errorText: `${PREFIX}-errorText`,
  emptyFooter: `${PREFIX}-emptyFooter`,
};

export const StyledInputLabel = memo(
  styled(Box)(({ theme }: { theme: Theme }) => ({
    [`&.${zeffyInputLabelClasses.container}`]: {
      width: "100%",
    },

    [`& .${zeffyInputLabelClasses.header}`]: {
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      margin: `0 0 ${theme.spacing(0.5)}`,
    },

    [`& .${zeffyInputLabelClasses.labelContainer}`]: {
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      margin: `0 ${theme.spacing(1)} 0 0`,
    },

    [`& .${zeffyInputLabelClasses.labelNeutral}`]: getLabelStyles("neutral", theme),
    [`& .${zeffyInputLabelClasses.labelForm}`]: getLabelStyles("form", theme),

    [`& .${zeffyInputLabelClasses.headerRight}`]: {
      alignItems: "center",
      display: "flex",
      justifyContent: "flex-end",
      overflowWrap: "anywhere",
      textAlign: "right",
    },

    [`& .${zeffyInputLabelClasses.footerText}`]: {
      marginTop: theme.spacing(0.5),
    },

    [`& .${zeffyInputLabelClasses.helperText}`]: {},
    [`& .${zeffyInputLabelClasses.errorText}`]: {},

    [`& .${zeffyInputLabelClasses.emptyFooter}`]: {
      marginTop: theme.spacing(0.5),
      minHeight: 16,
    },
  }))
);
