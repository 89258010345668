import { forwardRef } from "react";

import classnames from "classnames";

import { useCurrentInputVibe } from "../../../hooks/useCurrentInputVibe";
import { useCustomInputProps } from "../../../hooks/useCustomInputProps";
import { useMergeClasses } from "../useMergeClasses";

import { zeffyInputClasses, StyledInputBase } from "./styles";
import { BaseInputProps } from "./types";
export const BaseInput = forwardRef<HTMLInputElement, BaseInputProps>(
  (
    {
      classes: externalClasses,
      "data-test": dataTest,
      disableAutocomplete,
      disabled,
      error,
      inputProps,
      vibe,
      noPadding,
      placeholder,
      size = "medium",
      ...rest
    },
    ref
  ) => {
    const currentInputVibe = useCurrentInputVibe(vibe);

    const customInputProps = useCustomInputProps({
      inputProps,
      dataTest,
      disableAutocomplete,
    });

    const classes = useMergeClasses(zeffyInputClasses, externalClasses);

    return (
      <StyledInputBase
        {...rest}
        ref={ref}
        classes={{
          root: classnames(classes.root, {
            [classes.neutral]: currentInputVibe === "neutral",
            [classes.form]: currentInputVibe !== "neutral",
            [classes.disabled]: disabled,
            [classes.noPadding]: noPadding,
          }),
          input: classnames(classes.input, {
            [classes.medium]: size !== "small",
            [classes.small]: size === "small",
            [classes.noPadding]: noPadding,
          }),
          multiline: classes.multiline,
          adornedEnd: classes.adornedEnd,
          adornedStart: classes.adornedStart,
          inputAdornedEnd: classes.inputAdornedEnd,
          inputAdornedStart: classes.inputAdornedStart,
          focused: classes.focused,
          error: classes.error,
        }}
        disabled={disabled}
        error={error}
        fullWidth
        inputProps={customInputProps}
        placeholder={placeholder}
      />
    );
  }
);

BaseInput.displayName = "BaseInput";
