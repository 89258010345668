import { CSSProperties, forwardRef, useMemo } from "react";

import Box from "@mui/material/Box";
import classnames from "classnames";

import { Button } from "../Button";
import { IconButton } from "../IconButton";
import { ForwardRefComponentDS } from "../types";
import { Typography } from "../Typography";
import { useMergeClasses } from "../useMergeClasses";

import { InfoboxPointer } from "./InfoboxPointer";
import { zeffyInfoboxClasses, internalPopoverClasses, pointerHeight, StyledInfobox, StyledPopover } from "./styles";
import { InfoboxBaseClasses, InfoboxComponentProps, InfoboxProps, InfoboxVibe } from "./types";

import { Icon } from "@/design-system/Icon";
import { Link } from "@/design-system/Link";
import { Error, Success as CheckCircle, Cross as Close, Info, Warning } from "@/icons/outlined";

const InfoboxDefaultIcon: Record<InfoboxVibe, React.ReactNode> = {
  brand: <Info />,
  danger: <Error />,
  warning: <Warning />,
  positive: <CheckCircle />,
  neutral: undefined,
};

export const infoboxTitleDataTest = "infobox-title";
export const infoboxCloseButtonDataTest = "infobox-close-button";
export const DEFAULT_INFOBOX_VIBE = "brand";
export const DEFAULT_INFOBOX_SIZE = "medium";
export const DEFAULT_INFOBOX_TYPE = "alert";

const getArrowMarginStyles = (pointer: InfoboxProps["pointer"]): CSSProperties | undefined => {
  if (!pointer) {
    return;
  }
  if (pointer.includes("top")) {
    return { marginTop: pointerHeight };
  }
  if (pointer.includes("bottom")) {
    return {
      marginBottom: pointerHeight,
    };
  }
  if (pointer === "left") {
    return {
      marginLeft: pointerHeight,
    };
  }
  if (pointer === "right") {
    return {
      marginRight: pointerHeight,
    };
  }
};

const InfoboxComponent = forwardRef<HTMLDivElement, InfoboxComponentProps>(
  (
    {
      pointer,
      children,
      classes: externalClasses,
      onClose,
      icon,
      hideIcon,
      centerIcon,
      className,
      title,
      slots,
      vibe = DEFAULT_INFOBOX_VIBE,
      buttonsProps = [],
      inlineLinkProps = [],
      size = DEFAULT_INFOBOX_SIZE,
      titleVariant = "subtitle1",
      "data-test": dataTest,
      customButton,
      sx,
      ...rest
    },
    ref
  ) => {
    const classes = useMergeClasses(zeffyInfoboxClasses, externalClasses);

    const hasButtons = buttonsProps.length > 0;
    const isSizeSmall = size === "small";
    const currentIcon = icon ?? InfoboxDefaultIcon[vibe];

    const buttons = buttonsProps.map(
      ({ key, variant, vibe: buttonVibe, children: actionChildren, ...restOfButtonProps }, index) => {
        const isLastButton = index === buttonsProps.length - 1;
        const currentVibe = buttonVibe ?? vibe;
        return (
          <Button
            {...restOfButtonProps}
            className={classes.button}
            key={key}
            variant={variant ?? (isLastButton && !isSizeSmall ? "filled" : "tonal")}
            vibe={currentVibe === "neutral" ? "brand" : currentVibe}
          >
            {actionChildren}
          </Button>
        );
      }
    );

    const hasIcon = !hideIcon && currentIcon;

    return (
      <StyledInfobox
        {...rest}
        style={{ ...getArrowMarginStyles(pointer), ...rest.style }}
        sx={sx}
        className={classnames(className, classes.wrapper, classes[vibe])}
        data-test={dataTest}
        ref={ref}
      >
        <Box className={classes.body}>
          {slots?.left}
          <div className={classes.paper}>
            <div className={classes.container}>
              <div
                className={classnames(classes.iconAndContent, {
                  [classes.centerIcon]: centerIcon && hasIcon && isSizeSmall,
                })}
              >
                {hasIcon && (
                  <Icon size="large" vibe={`text-${vibe}-quiet`} className={classnames(classes.icon, {})}>
                    {currentIcon}
                  </Icon>
                )}
                <div className={classnames(classes.content, { [classes.contentPaddingTop]: !title || isSizeSmall })}>
                  {title && !isSizeSmall && (
                    <Typography
                      className={classes.title}
                      variant={titleVariant}
                      vibe={`text-${vibe}-intense`}
                      data-test={infoboxTitleDataTest}
                    >
                      {title}
                    </Typography>
                  )}
                  {children && (
                    <Typography variant="body2" vibe={`text-${vibe}-moderate`} component="div">
                      {children}
                    </Typography>
                  )}
                  {inlineLinkProps.length > 0 && (
                    <div className={classes.linkContainer}>
                      {inlineLinkProps.map(({ key, ...restOfLinkProps }) => {
                        return (
                          <div key={key} className={classes.link}>
                            <Link {...restOfLinkProps} vibe={vibe} underline="always" />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              </div>
              <div className={classnames(classes.actions, { [classes.actionsSmall]: isSizeSmall })}>
                {hasButtons && isSizeSmall && <div className={classes.buttons}>{buttons?.[0]}</div>}
                {customButton && isSizeSmall && <div className={classes.buttons}>{customButton}</div>}
                {onClose && (
                  <div className={classnames(classes.close, { [classes.closeMedium]: !isSizeSmall })}>
                    <IconButton
                      onClick={onClose}
                      variant="text"
                      vibe={vibe}
                      size="small"
                      data-test={infoboxCloseButtonDataTest}
                    >
                      <Close />
                    </IconButton>
                  </div>
                )}
              </div>
            </div>
            {hasButtons && !isSizeSmall && (
              <div className={classnames(classes.buttons, classes.buttonsMedium)}>{buttons}</div>
            )}
            {customButton && !isSizeSmall && (
              <div className={classnames(classes.buttons, classes.buttonsMedium)}>{customButton}</div>
            )}
          </div>
          {slots?.right}
        </Box>
        {pointer && <InfoboxPointer position={pointer} vibe={vibe} />}
      </StyledInfobox>
    );
  }
);

InfoboxComponent.displayName = "Infobox";

export const Infobox = forwardRef<HTMLDivElement, InfoboxProps>(
  (
    {
      anchorEl,
      pointer,
      classes,
      onClose,
      open,
      popoverProps,
      propegatePopoverOnClose = false,
      type = DEFAULT_INFOBOX_TYPE,
      vibe = DEFAULT_INFOBOX_VIBE,
      ...rest
    },
    ref
  ) => {
    const infobox = (
      <InfoboxComponent
        {...rest}
        pointer={type === "popover" ? undefined : pointer}
        classes={classes}
        vibe={vibe}
        ref={ref}
        onClose={onClose}
      />
    );

    const currentPopoverProps: InfoboxProps["popoverProps"] = useMemo(
      () => ({
        ...popoverProps,
        anchorOrigin: popoverProps?.anchorOrigin ?? {
          vertical: "bottom",
          horizontal: "center",
        },
        transformOrigin: popoverProps?.transformOrigin ?? {
          vertical: "top",
          horizontal: "center",
        },
      }),
      [popoverProps]
    );

    const currentPopoverClasses = useMemo(
      () => ({
        ...popoverProps?.classes,
        paper: classnames(popoverProps?.classes?.paper, internalPopoverClasses.popover),
      }),
      [popoverProps?.classes]
    );

    if (type === "popover") {
      return (
        <StyledPopover
          {...currentPopoverProps}
          classes={currentPopoverClasses}
          anchorEl={anchorEl}
          open={!!open}
          slotProps={{
            paper: {
              style: getArrowMarginStyles(pointer),
            },
          }}
          onClose={propegatePopoverOnClose ? onClose : undefined}
        >
          {infobox}
          {pointer && <InfoboxPointer vibe={vibe} position={pointer} />}
        </StyledPopover>
      );
    }

    return infobox;
  }
) as ForwardRefComponentDS<InfoboxProps, HTMLDivElement, InfoboxBaseClasses>;

Infobox.displayName = "Infobox";
