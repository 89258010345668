import { FC } from "react";

import classnames from "classnames";

import Pointer from "./pointer.svg";
import { internalPointerClasses, StyledIcon } from "./styles";
import { InfoboxPointerProps } from "./types";

export const InfoboxPointer: FC<InfoboxPointerProps> = ({ position, vibe }) => (
  <StyledIcon
    className={classnames(internalPointerClasses.root, internalPointerClasses[vibe], internalPointerClasses[position], {
      [internalPointerClasses.top]: position.includes("top"),
      [internalPointerClasses.bottom]: position.includes("bottom"),
    })}
  >
    <Pointer />
  </StyledIcon>
);
