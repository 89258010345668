export const isObjectEmpty = (obj) => {
    for (const prop in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, prop)) {
            return false;
        }
    }
    return true;
};
export const Utils = {
    filterObject: (obj, predicate) => Object.fromEntries(Object.entries(obj).filter(predicate)),
    contains: (array, subArray) => {
        return subArray.length > 0 ? subArray.every((element) => array.includes(element)) : false;
    },
    arrayToIdMap: (values) => {
        const map = values.reduce((prev, current) => {
            prev.set(current.id, current);
            return prev;
        }, new Map());
        return Object.fromEntries(map);
    },
    arrayToKeyMap: (values, getKey) => {
        const map = values.reduce((prev, current) => {
            const key = getKey(current);
            prev.set(key, current);
            return prev;
        }, new Map());
        return Object.fromEntries(map);
    },
    groupBy: (values, keyCallback) => {
        const map = values.reduce((prev, current) => {
            const key = keyCallback(current);
            const existingValues = prev.get(key) ?? [];
            existingValues.push(current);
            prev.set(key, existingValues);
            return prev;
        }, new Map());
        return Object.fromEntries(map);
    },
    sortByIndex: (a, b) => a.sortIndex - b.sortIndex,
    mergeWithProperty: (objects, valuesToAdd, callback) => {
        const valuesToAddMap = new Map();
        valuesToAdd.forEach((value) => valuesToAddMap.set(value.id, value));
        return objects.map((existingValue) => {
            const newValue = valuesToAddMap.get(existingValue.id);
            const result = callback(newValue);
            return {
                ...existingValue,
                ...result,
            };
        });
    },
    removeDoublesByProperty: (array, key) => {
        const keyMap = array.reduce((prev, current) => {
            const value = current[key];
            return { ...prev, [value]: current };
        }, {});
        return Array.from(new Set(array.map((item) => item[key]))).map((value) => keyMap[value]);
    },
    notEmpty(value) {
        if (value === null || value === undefined || value === "") {
            return false;
        }
        if (typeof value !== "object") {
            return true;
        }
        if (Array.isArray(value)) {
            return value.length > 0;
        }
        const proto = Object.getPrototypeOf(value);
        if (proto !== null && proto !== Object.prototype) {
            return true;
        }
        return !isObjectEmpty(value);
    },
    filterAndMap(arr, filterFn, mapFn) {
        const result = [];
        let mapIndex = 0;
        for (let i = 0; i < arr.length; i++) {
            const item = arr[i];
            if (filterFn(item)) {
                result.push(mapFn(item, mapIndex++));
            }
        }
        return result;
    },
    /**
     * Removes nested keys from an object.
     * @param obj The object to remove keys from.
     * @param keysToRemove The keys to remove.
     * @param currentLevel The current level of recursion.
     * @param maxLevel The maximum level of recursion.
     * @param strict If true, the key must match exactly. If false, the key must be included in the object key.
     * @returns The object with the keys removed.
     */
    removeNestedKeys({ obj, keysToRemove, currentLevel = 0, maxLevel = 4, strict = true, }) {
        if (currentLevel > maxLevel || typeof obj !== "object" || obj === null) {
            return obj;
        }
        if (Array.isArray(obj)) {
            return obj.map((item) => this.removeNestedKeys({ obj: item, keysToRemove, currentLevel, maxLevel, strict }));
        }
        const newObj = { ...obj };
        Object.keys(newObj).forEach((key) => {
            const lowerCasesKeysToRemove = keysToRemove.map((key) => key.toLowerCase());
            const shouldRemoveKey = strict
                ? lowerCasesKeysToRemove.includes(key.toLowerCase())
                : lowerCasesKeysToRemove.some((keyToRemove) => key.toLowerCase().includes(keyToRemove));
            if (shouldRemoveKey) {
                delete newObj[key];
            }
            else if (typeof newObj[key] === "object" && !Array.isArray(newObj[key]) && newObj[key] !== null) {
                newObj[key] = this.removeNestedKeys({
                    obj: newObj[key],
                    keysToRemove,
                    currentLevel: currentLevel + 1,
                    maxLevel,
                    strict,
                });
            }
        });
        return newObj;
    },
    takeRand(array) {
        return array[Math.floor(Math.random() * array.length)];
    },
};
export function promiseWithTimeout(promise, timeout) {
    return new Promise((resolve, reject) => {
        const timer = setTimeout(() => {
            reject(new Error("promise timeout"));
        }, timeout);
        return promise
            .then((result) => {
            clearTimeout(timer);
            resolve(result);
        })
            .catch((error) => {
            clearTimeout(timer);
            reject(error);
        });
    });
}
