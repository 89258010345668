import { useState, useRef, useEffect, useCallback } from "react";

interface DebounceProps<T> {
  onChange?: (value: T) => void;
  debounced?: number;
}

export const useDebounce = <T = unknown>({ onChange, debounced }: DebounceProps<T>) => {
  const [debouncedValue, setDebouncedValue] = useState<T>();
  const [isWaiting, setIsWaiting] = useState(false);

  const firstUpdate = useRef(true);
  const timerRef = useRef<number | null>(null);

  // Function to cancel the debounced function
  const cancel = useCallback(() => {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
      setIsWaiting(false);
    }
  }, []);

  useEffect(() => {
    // Avoid triggering error on first render
    if (firstUpdate.current) {
      firstUpdate.current = false;
      return;
    }
    setIsWaiting(true);
    timerRef.current = setTimeout(() => {
      setIsWaiting(false);
      onChange?.(debouncedValue as T);
    }, debounced);

    return cancel;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue]);

  return { setDebouncedValue, isWaiting, cancel };
};
