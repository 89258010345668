import { forwardRef } from "react";

import { LinkProps as MaterialLinkProps } from "@mui/material/Link";
import classnames from "classnames";

import { useInteractiveElementEvents } from "../../../hooks/useInteractiveElementEvents";
import { useMergeClasses } from "../useMergeClasses";

import { zeffyLinkClasses, StyledLink } from "./styles";
import { LinkProps } from "./types";

export const Link = forwardRef<HTMLAnchorElement, LinkProps>(
  (
    {
      className,
      classes: externalClasses,
      vibe = "brand",
      fullWidth,
      onClick,
      onKeyDown,
      onKeyUp,
      tabIndex,
      href,
      pointer,
      underline = "hover",
      sx,
      ...rest
    },
    ref
  ) => {
    const classes = useMergeClasses(zeffyLinkClasses, externalClasses);

    const { handleKeyDown, handleKeyUp } = useInteractiveElementEvents<HTMLAnchorElement>({
      onClick,
      onKeyDown,
      onKeyUp,
    });

    return (
      <StyledLink
        className={classnames(classes.link, classes[vibe], className, {
          [classes.pointer]: Boolean(href) || Boolean(onClick) || pointer,
          [classes.fullWidth]: fullWidth,
        })}
        onClick={onClick as MaterialLinkProps["onClick"]}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        tabIndex={tabIndex !== undefined ? tabIndex : onClick ? 0 : undefined}
        ref={ref}
        href={href}
        // When href is missing, we want to render a span instead of an anchor tag for SEO reasons (crawlable links)
        // https://developers.google.com/search/docs/crawling-indexing/links-crawlable
        component={href ? "a" : "span"}
        underline={underline}
        {...rest}
        sx={sx}
      />
    );
  }
);

Link.displayName = "Link";
