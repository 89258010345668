import { forwardRef } from "react";

import { useTheme } from "@mui/material";
import classnames from "classnames";

import { useMergeClasses } from "../useMergeClasses";

import { zeffyInputLabelClasses, StyledInputLabel } from "./styles";
import { InputLabelProps } from "./types";

import { Icon } from "@/design-system/Icon/Icon";
import { Tooltip } from "@/design-system/Tooltip/Tooltip";
import { Typography } from "@/design-system/Typography/Typography";
import { Info } from "@/icons/outlined";

export const InputLabel = forwardRef<HTMLDivElement, InputLabelProps>(
  (
    {
      children,
      classes: externalClasses,
      className,
      errorMessage,
      helperText,
      headerRight,
      label,
      sx,
      tooltipText,
      vibe = "neutral",
      "data-test": dataTest,
    },
    ref
  ) => {
    const classes = useMergeClasses(zeffyInputLabelClasses, externalClasses);
    const theme = useTheme();

    return (
      <StyledInputLabel className={classnames(className, classes.container)} ref={ref} data-test={dataTest} sx={sx}>
        {(label || tooltipText || headerRight) && (
          <div className={classes.header}>
            <div className={classes.labelContainer}>
              {label && (
                <Typography
                  variant="body2"
                  className={classnames({
                    [classes.labelNeutral]: vibe === "neutral",
                    [classes.labelForm]: vibe !== "neutral",
                  })}
                  data-test="input-label-label-typography"
                  sx={
                    theme.constants.isFormV2
                      ? { fontWeight: 600, color: `${theme.palette.text.form.intense} !important` }
                      : {}
                  }
                >
                  {label}
                </Typography>
              )}
              {tooltipText && (
                <Tooltip title={tooltipText} arrow placement="top">
                  <Icon size="small" pointer>
                    <Info />
                  </Icon>
                </Tooltip>
              )}
            </div>
            <Typography variant="caption" vibe="text-neutral-quiet" className={classes.headerRight}>
              {headerRight}
            </Typography>
          </div>
        )}
        {children}
        {errorMessage ? (
          <Typography
            component="p"
            variant="caption"
            vibe="text-danger-quiet"
            className={classnames(classes.footerText, classes.errorText)}
          >
            {errorMessage}
          </Typography>
        ) : helperText ? (
          <Typography
            component="p"
            variant="caption"
            vibe="text-brand-moderate"
            className={classnames(classes.footerText, classes.helperText)}
          >
            {helperText}
          </Typography>
        ) : (
          <></>
        )}
      </StyledInputLabel>
    );
  }
);

InputLabel.displayName = "InputLabel";
