export const kebabCaseToCamelCase = (value) => {
    return value.replace(/([-][a-z])/gi, (group) => group.toUpperCase().replace("-", ""));
};
export const snakeCaseToCamelCase = (value) => {
    return value.replace(/([_][a-z])/gi, (group) => group.toUpperCase().replace("_", ""));
};
export const camelCaseToSnakeCase = (value) => {
    return value.replace(/[A-Z]/g, (match, offset) => (offset > 0 ? "_" : "") + match.toLowerCase());
};
export const pascalCaseToCamelCase = (value) => {
    return `${value[0].toLowerCase()}${value.substr(1, value.length)}`;
};
export const camelCaseToPascalCase = (value) => {
    return `${value[0].toUpperCase()}${value.substr(1, value.length)}`;
};
export const getFormattedMessageWithLineBreaks = (value) => {
    return value.replace(/\n/g, "<br />");
};
export const truncateString = (value, end) => {
    return value?.length > end ? `${value.substring(0, end)}...` : value;
};
export const capitalize = (string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};
// Remove circular dependencies from an object before stringifying it.
export const stringify = (obj) => {
    const cache = [];
    const str = JSON.stringify(obj, (_key, value) => {
        if (typeof value === "object" && value !== null) {
            if (cache.includes(value)) {
                // Circular reference found, discard key
                return;
            }
            // Store value in our collection
            cache.push(value);
        }
        return value;
    });
    // Reset the cache
    cache.length = 0;
    return str;
};
export const decodeHTMLEntities = (input) => input.replace(/&amp;/g, "&");
export const hasAtLeastNDigitCharacters = (value, n) => Boolean(value.match(new RegExp(`(\\D*\\d){${n},}`, "g"))?.length);
