import { useCallback, useContext } from "react";

import * as amplitude from "@amplitude/analytics-browser";
import { AMPLITUDE_GROUP_TYPE, camelCaseToSnakeCase } from "@simplyk/common";
import { browserName } from "react-device-detect";

import { AmplitudeEvents } from "../../constants/amplitude";
import { isProduction, isTest } from "../../constants/env";
import { AmplitudeContext } from "../../contexts/AmplitudeContext";
import { ConsentModeContext } from "../../contexts/ConsentModeContext";
import { useCurrentUserContext } from "../../contexts/CurrentUserContext";
import { SessionContext } from "../../contexts/SessionContext";
import { useMediaQuery } from "../useMediaQuery";

export type AmplitudeEventParams = {
  event: AmplitudeEvents | string;
  payload?: { [key: string]: unknown };
};

export type Primitive = string | number | boolean;
export interface GetExperimentValueProps {
  flagKey: string;
  userId?: string;
  organizationId?: string;
  userProperties?: Record<string, Primitive>;
  organizationProperties?: Record<string, Primitive>;
}
export const useAmplitude = () => {
  const { sessionId, isFraudulent } = useContext(SessionContext);
  const { organization } = useCurrentUserContext();
  const { hasFunctionalityConsentMode } = useContext(ConsentModeContext);
  const { experimentClient, isAmplitudeLoading } = useContext(AmplitudeContext);
  const { isSmallScreen } = useMediaQuery();

  const currentUserOrganizationId = organization?.id;

  const getExperimentValue = useCallback(
    async ({ flagKey, userId, organizationId, userProperties, organizationProperties }: GetExperimentValueProps) => {
      // This fetch is necessary to make sure we set the props on the user and get the correct
      // variant value based on the user properties. It seems that identify does not populate
      // the data on the user in time.
      const userProps = userProperties ? formatAmplitudeProperties(userProperties) : undefined;
      const groupProps = organizationProperties ? formatAmplitudeProperties(organizationProperties) : undefined;

      if (userId || organizationId || userProps || groupProps) {
        await experimentClient?.fetch({
          user_id: userId,
          groups: organizationId ? { [AMPLITUDE_GROUP_TYPE]: [organizationId] } : undefined,
          user_properties: userProps,
          group_properties:
            organizationId && groupProps
              ? {
                  [AMPLITUDE_GROUP_TYPE]: {
                    [organizationId]: groupProps,
                  },
                }
              : undefined,
        });
      }

      return experimentClient?.variant(flagKey);
    },
    [experimentClient]
  );

  const logAmplitudeEvent = useCallback(
    (event: AmplitudeEventParams["event"], payload?: AmplitudeEventParams["payload"]) => {
      if (isTest || isFraudulent || !hasFunctionalityConsentMode) {
        return;
      }
      const fullPayload = {
        ...payload,
        zeffySessionId: sessionId,
        device: isSmallScreen ? "mobile" : "desktop",
        browser: browserName,
      };
      if (!isProduction) {
        // eslint-disable-next-line no-console
        console.log(event, fullPayload);
      }
      if (typeof window !== "undefined") {
        amplitude.track({
          event_type: event,
          event_properties: fullPayload,
          groups: currentUserOrganizationId ? { organization: currentUserOrganizationId } : undefined,
        });
      }
    },
    [isFraudulent, hasFunctionalityConsentMode, sessionId, isSmallScreen, currentUserOrganizationId]
  );

  const logAmplitudeEventCallback = useCallback(
    (event: AmplitudeEventParams["event"], payload?: AmplitudeEventParams["payload"]) => () => {
      logAmplitudeEvent(event, payload);
    },
    [logAmplitudeEvent]
  );

  // used to reset the amplitude user on signout
  const resetAmplitude = useCallback(() => {
    if (isTest || isFraudulent || !hasFunctionalityConsentMode) {
      return;
    }
    amplitude.reset();
  }, [hasFunctionalityConsentMode, isFraudulent]);

  return {
    logAmplitudeEvent,
    logAmplitudeEventCallback,
    getExperimentValue,
    isAmplitudeLoading,
    resetAmplitude,
  };
};

const formatAmplitudeProperties = (props: Record<string, Primitive>): { [propertyName: string]: Primitive } => {
  const userProps: { [propertyName: string]: Primitive } = {};
  Object.keys(props).forEach((key) => {
    const v = props[key as keyof typeof props];
    // eslint-disable-next-line eqeqeq
    if (v === null || v === undefined) {
      return;
    }

    const k = camelCaseToSnakeCase(key);
    userProps[k] = v.toString();
  });

  return userProps;
};
