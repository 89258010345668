import { RefAttributes, forwardRef, Ref, type JSX } from "react";

import { FieldValues } from "react-hook-form";

import { BaseTextField } from "./BaseTextField";
import { FormTextField } from "./FormTextField";
import { TextFieldProps } from "./types";

function TextFieldInner<T extends FieldValues>(
  { control, rules, name, shouldUnregister, ...rest }: TextFieldProps<T>,
  ref: Ref<HTMLInputElement>
) {
  return control && name ? (
    <FormTextField
      {...rest}
      ref={ref}
      control={control}
      rules={rules}
      name={name}
      shouldUnregister={shouldUnregister}
    />
  ) : (
    <BaseTextField {...rest} name={name} ref={ref} />
  );
}

TextFieldInner.displayName = "TextField";

export const TextField = forwardRef(TextFieldInner) as <T extends FieldValues>(
  props: TextFieldProps<T> & RefAttributes<HTMLInputElement>
) => JSX.Element;
