import { FormType, DashboardRoutes, RoutePrefix } from "@simplyk/common";
import { stringify } from "query-string";

import { BadgeVibe } from "../components/design-system/Badge";
import { Locales } from "../gql/gql-types";

export const FRONTEND_URL = process.env.NEXT_PUBLIC_FRONTEND_URL as string;

export const getEditFormLink = ({
  formId,
  formType,
  params,
  tag,
}: {
  formId: string;
  formType: FormType;
  params?: Record<string, unknown>;
  tag?: string;
}): string => {
  const queryParams = params ? `?${stringify(params || {})}` : "";
  const tagParams = tag ? `#${tag}` : "";
  if (formType === FormType.Ticketing) {
    return `/o${DashboardRoutes.TicketingEdit.replace(":id", formId)}${queryParams}${tagParams}`;
  } else if (formType === FormType.DonationForm) {
    return `/o${DashboardRoutes.DonationFormsEdit.replace(":id", formId)}${queryParams}${tagParams}`;
  }
  return "";
};

export interface OrganizationRoutesInterface {
  DonationFormsNew: DashboardRoutes.DonationFormsNew;
  DonationFormsEdit: DashboardRoutes.DonationFormsEdit;
  ReceiptGeneration: DashboardRoutes.ReceiptGeneration;
  NotFound: DashboardRoutes.NotFound;
  Bank: DashboardRoutes.Bank;
  Payouts: DashboardRoutes.Payouts;
  Transactions: DashboardRoutes.Transactions;
  PayoutTransactions: DashboardRoutes.PayoutTransactions;
  Receipts: DashboardRoutes.BankReceipts;
  ContactsReceipts: DashboardRoutes.ContactsReceipts;
  TicketingNew: DashboardRoutes.TicketingNew;
  TicketingEdit: DashboardRoutes.TicketingEdit;
  SavedFeeInfo: DashboardRoutes.SavedFeeInfo;
  MFALogin: DashboardRoutes.MFALogin;
  SavedFeeCongratulation: DashboardRoutes.SavedFeeCongratulation;
  FormTable: DashboardRoutes.FormTable;
  PaymentTable: DashboardRoutes.PaymentTable;
  User: DashboardRoutes.User;
  Contacts: DashboardRoutes.Contacts;
  Visitors: DashboardRoutes.Visitors;
  EmailDeliveries: DashboardRoutes.EmailDeliveries;
  CreateNewOrganization: DashboardRoutes.CreateNewOrganization;
  CreateBankAccount: DashboardRoutes.CreateBankAccount;
  UpdateBankAccount: DashboardRoutes.UpdateBankAccount;
  Referral: DashboardRoutes.Referral;
  Settings: DashboardRoutes.Settings;
  FormsNew: DashboardRoutes.FormsNew;
  Commands: DashboardRoutes.Commands;
  CostsIndicator: DashboardRoutes.CostsIndicator;
  Import: DashboardRoutes.Import;
  ImportLoading: DashboardRoutes.ImportLoading;
  StripeOnboarding: DashboardRoutes.StripeOnboarding;
  Home: DashboardRoutes.Home;
  IssuingOnboarding: DashboardRoutes.IssuingOnboarding;
  Qa: DashboardRoutes.Qa;
  ImportGMailContacts: DashboardRoutes.ImportGMailContacts;
  ImportMailChimpContacts: DashboardRoutes.ImportMailChimpContacts;
  FullScreenEmailEditor: DashboardRoutes.FullScreenEmailEditor;
  Accounts: DashboardRoutes.Accounts;
  CampaignHub: DashboardRoutes.CampaignHub;
  CampaignPayments: DashboardRoutes.CampaignPayments;
  TicketingV2New: DashboardRoutes.TicketingV2New;
  AllPayments: DashboardRoutes.AllPayments;
}

export interface DonorRoutesInterface {
  DonationFormsEdit: DashboardRoutes.DonationFormsEdit;
  Settings: DashboardRoutes.Settings;
  CreateNewOrganization: DashboardRoutes.CreateNewOrganization;
  DonorCampaigns: DashboardRoutes.DonorCampaigns;
  DonorPaymentTable: DashboardRoutes.DonorPaymentTable;
}

export interface PublicRoutesInterface {
  SignIn: DashboardRoutes.SignIn;
  SignInAdmin: DashboardRoutes.SignInAdmin;
  SignOut: DashboardRoutes.SignOut;
  SignUp: DashboardRoutes.SignUp;
  SignUpPostDemo: DashboardRoutes.SignUpPostDemo;
  ResetPassword: DashboardRoutes.ResetPassword;
  TicketScanned: DashboardRoutes.TicketScanned;
  DonationFormsNew: DashboardRoutes.DonationFormsNew;
  DonationFormsEdit: DashboardRoutes.DonationFormsEdit;
  TicketingNew: DashboardRoutes.TicketingNew;
  TicketingEdit: DashboardRoutes.TicketingEdit;
  CreateBankAccount: DashboardRoutes.CreateBankAccount;
  MergeUsers: DashboardRoutes.MergeUsers;
  Referral: DashboardRoutes.Referral;
  VerifyEmail: DashboardRoutes.VerifyEmail;
  CheckinManager: DashboardRoutes.CheckinManager;
  ExpiredBidderLink: DashboardRoutes.ExpiredBidderLink;
  GivingTuesdayMap: DashboardRoutes.GivingTuesdayMap;
}

export const OrganizationRoutes = Object.fromEntries(
  Object.entries(DashboardRoutes).map(([key, value]) => [key, RoutePrefix.Organization + value])
) as unknown as OrganizationRoutesInterface;

export const DonorRoutes = Object.fromEntries(
  Object.entries(DashboardRoutes).map(([key, value]) => [key, RoutePrefix.Donor + value])
) as unknown as DonorRoutesInterface;

export const PublicRoutes = Object.fromEntries(
  Object.entries(DashboardRoutes).map(([key, value]) => [key, value])
) as unknown as PublicRoutesInterface;

export const LandingPageUrl =
  process.env.NODE_ENV === "production"
    ? {
        [Locales.EN]: "https://www.zeffy.com/",
        [Locales.ES]: "https://www.zeffy.com/",
        [Locales.FR]: "https://fr.zeffy.com/",
      }
    : {
        [Locales.EN]: process.env.NEXT_PUBLIC_FRONTEND_URL + "/login",
        [Locales.ES]: process.env.NEXT_PUBLIC_FRONTEND_URL + "/login",
        [Locales.FR]: process.env.NEXT_PUBLIC_FRONTEND_URL + "/login",
      };

export enum SubMenu {
  // ! DEPRECTAED
  Donations = "donations",
  // ! DEPRECTAED
  Tickets = "tickets",
  Bank = "bank",
  Contact = "contacts",
  Referral = "referral",
  Forms = "forms",
  Home = "home",
  // ! DEPRECTAED
  Payments = "payments",
}

export enum SubMenuItem {
  // ! DEPRECTAED
  Donations = "donations.donations",
  // ! DEPRECTAED
  DonationsForms = "donations.forms",
  // ! DEPRECTAED
  TicketingTickets = "ticketings.tickets",
  // ! DEPRECTAED
  TicketingSold = "ticketings.sold",
  Contacts = "contacts.contacts",
  Visitors = "contacts.visitors",
  EmailDeliveries = "contacts.emailDeliveries",
  Bank = "bank.overview",
  Payouts = "bank.payouts",
  Receipts = "bank.receipts",
  Transactions = "bank.transactions",
  Forms = "fundraising.forms",
  Payments = "fundraising.payments",
  Balance = "bank.balance",
  Accounts = "bank.accounts",
}

export interface MenuItem {
  name: SubMenu | SubMenuItem;
  isActive: boolean;
  iconSrc?: React.FC<React.SVGProps<SVGSVGElement>>;
  isOpen?: boolean;
  path: string;
  items?: MenuItem[];
  openInNewTab?: boolean;
  isFeatureActivated: boolean;
  isFeatureAllowed: boolean;
  displayNotificationDot?: boolean;
  notificationDotVibe?: BadgeVibe;
  label?: string;
}
